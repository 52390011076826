<template>
    <validation-observer ref="SuporteDuvidasForm">
        <b-form id="SuporteDuvidasForm">
            <b-row
                class="text-left"
                :class="FormularioDeDuvida == true ? 'd-none' : ''"
            >
                <b-col md="6" xl="6">
                    <!-- nome -->
                    <b-form-group label="Nome *" label-for="nome">
                        <validation-provider
                            #default="{ errors }"
                            name="Seu nome completo"
                            :rules="validacoes.nome"
                        >
                            <b-form-input
                                id="nome"
                                placeholder="Seu nome completo"
                                v-model="nome"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <b-col md="6" xl="6">
                    <!-- email -->
                    <b-form-group label="E-mail *" label-for="email">
                        <validation-provider
                            #default="{ errors }"
                            name="Seu e-mail"
                            :rules="validacoes.email"
                        >
                            <b-form-input
                                id="email"
                                placeholder="Seu e-mail"
                                v-model="email"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <b-col md="6" xl="6">
                    <!-- telefone -->
                    <b-form-group label="Telefone" label-for="telefone">
                        <b-form-input
                            id="telefone"
                            placeholder="Seu e-mail"
                            v-model="telefone"
                        />
                    </b-form-group>
                </b-col>

                <b-col md="6" xl="6">
                    <!-- empresa -->
                    <b-form-group label="Empresa" label-for="empresa">
                        <b-form-input
                            id="empresa"
                            placeholder="Nome da empresa"
                            v-model="empresa"
                        />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row class="text-left">
                <b-col class="col-10 mx-auto">
                    <!-- assunto -->
                    <b-form-group label="Assunto *" label-for="assunto">
                        <validation-provider
                            #default="{ errors }"
                            name="Assunto"
                            :rules="validacoes.assunto"
                        >
                            <b-form-input
                                id="assunto"
                                :placeholder="placeholder.assunto"
                                v-model="assunto"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <b-col class="col-10 mx-auto">
                    <!-- mensagem -->
                    <b-form-group label="Mensagem *" label-for="mensagem">
                        <validation-provider
                            #default="{ errors }"
                            name="Assunto"
                            :rules="validacoes.mensagem"
                        >
                            <b-form-textarea
                                id="mensagem"
                                :placeholder="placeholder.mensagem"
                                v-model="mensagem"
                                :state="errors.length > 0 ? false : null"
                            ></b-form-textarea>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <b-col class="col-12 mb-1 text-center">
                    <b-button
                        type="submit"
                        variant="custom"
                        :disabled="bloquearEnvio"
                        @click.prevent="enviarFormulario"
                    >
                        Enviar
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>
</template>

<script>
import {
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormTextarea,
    BForm,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { modalGenericModel } from '@/libs/sweetalerts'
export default {
    components: {
        BFormInput,
        BFormTextarea,
        BFormGroup,
        BButton,
        BRow,
        BCol,
        Cleave,
        ValidationProvider,
        ValidationObserver,
        BForm,
    },
    props: {
        FormularioDeDuvida: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            validacoes: {
                nome: "",
                email: "",
                assunto: "",
                mensagem: "",
            },
            placeholder: {
                assunto: "Assunto da mensagem",
                mensagem: "Como podemos te ajudar? :)",
            },
            tipoDeContato: "Solicitação de Suporte",
            nome: "",
            email: "",
            telefone: "",
            empresa: "",
            assunto: "",
            mensagem: "",
        };
    },
    mounted() {
        if (this.FormularioDeDuvida) {
            let userData = this.$store.state.sessions.userData;

            this.placeholder.assunto = "Objetivo da sua mensagem";
            this.placeholder.mensagem = "Explique como podemos te ajudar :)";

            this.tipoDeContato = "Dúvidas";
            this.nome = userData.pessoa.nome;
            this.email = userData.usuario.email;
            this.telefone = userData.pessoa.telefone;
            this.empresa = userData.empresa.razao;
        }
    },
    methods: {
        limpaFormulario() {
            if (!this.FormularioDeDuvida) {
                this.nome = "";
                this.email = "";
                this.telefone = "";
                this.empresa = "";
            }

            this.assunto = "";
            this.mensagem = "";

            this.validacoes.nome = "";
            this.validacoes.email = "";
            this.validacoes.assunto = "";
            this.validacoes.mensagem = "";

            this.$refs.SuporteDuvidasForm.reset();
        },
        enviarFormulario() {
            this.validacoes.nome = "required";
            this.validacoes.email = "required|email";
            this.validacoes.assunto = "required";
            this.validacoes.mensagem = "required";

            this.$refs.SuporteDuvidasForm.validate().then((success) => {
                if (success) {
                    let dados = {
                        tipo_de_contato: this.tipoDeContato,
                        nome: this.nome,
                        email: this.email,
                        telefone: this.telefone,
                        empresa: this.empresa,
                        assunto: this.assunto,
                        mensagem: this.mensagem,
                    };

                    this.$http
                        .post(this.$api.FaqAjudasEnviarEmailDuvida, dados)
                        .then(() => {
                          this.limpaFormulario();
                          modalGenericModel(
                            'Mensagem enviado com sucesso',
                            'Assim que possível entraremos em contato com você',
                            require('@/assets/custom-icons/cora-icons/success.png'),
                            'Ok'
                          )
                        }).catch(() => {
                          this.limpaFormulario();
                          modalGenericModel(
                            'Mensagem não enviada',
                            'Tente novamente mais tarde',
                            require('@/assets/custom-icons/cora-icons/error.png'),
                            'Ok'
                          )
                        });
                }
            });
        },
    },
    computed: {
        bloquearEnvio: function () {
            return !(this.nome.length > 0 &&
                this.email.length > 0 &&
                this.assunto.length > 0 &&
                this.mensagem.length > 0);
        },
    },
};
</script>

<style scoped>
#SuporteDuvidasForm textarea#mensagem {
    height: 190px;
}
#SuporteDuvidasForm .btn.btn-custom {
    width: 138px;
}
</style>
